
/**
 * Description of contact.js
 *
 * @author		Horatiu Cristea-Lubinschi <horatiu.cristea@gmail.com>
 * @copyright	(c) 
 * @date		Nov 15, 2018
 * @encoding	UTF-8 
 */

/**
 * 
 * @param {type} frmSelector
 * @returns {undefined}
 */
var ContactForm = function(frmSelector){
	var me = this, 
		$frm = $(frmSelector), 
		$els = $frm.find('input, textarea'),
		$btn = $frm.find('button'), 
		validateEmail, 
		validateField, 
		validate;
		
	validateEmail = function(email){
		var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return pattern.test(email.toLowerCase());
	};
	validateField = function($el){
		var isValid = true, 
			msg = 'Câmplul acesta trebuie completat.';
		$el.removeClass('is-invalid').removeClass('is-valid');
		$el.next('.invalid-feedback').remove();
		isValid = $el.val().trim() !== '';
		if (isValid && $el.attr('type') === 'email'){
			if (!validateEmail($el.val())){
				isValid = false;
				msg = 'Adresa de email introdusă nu pare să aibă o formă corectă.';
			}
		}
		if (!isValid){
			$el.addClass('is-invalid');
			$el.after('<div class="invalid-feedback">' + msg + '</div>');
			return false;				
		}
		$el.addClass('is-valid');
		return true;
	};
	
	validate = function(){
		var isValid = true;
		$frm.find('.invalid-feedback').remove();
		$els.each(function(){
			var $el = $(this);
			if ($el.is(':hidden')) {
				return true;
			}
			isValid = validateField($el) && isValid;
		});
		return isValid;
	};
	
	$els.keyup(function(e){
		validateField($(e.currentTarget));
	});

	$frm.on('submit', function(e){
		var jsonData = {},  
			origText = $btn.html();
		$els.each(function(){
			var $el = $(this);
			jsonData[$el.attr('name')] = $el.val();
		});
		
		if (!validate()) {
			$els.filter('.is-invalid').filter(':first').focus();
			return false;
		}
		
		$btn.html($btn.data('loading-text'));
		$btn.attr('disabled', 'disabled');
		$.ajax({
			method: 'POST',
			data: jsonData, 
			success: function(r){
				$frm.append(r);
			},
			complete: function(){
				$btn.html(origText);
				setTimeout(function(){
					$btn.removeAttr('disabled');
				}, 1000);
			}
		});
		
		return false;
	});
};